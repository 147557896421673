import type { SyntheticEvent } from "react";
import React, { useMemo, useRef } from "react";

import * as RadixDialog from "@radix-ui/react-dialog";
import type { ClassValue } from "clsx";
import type { AnimationProps, PanInfo } from "framer-motion";
import { AnimatePresence, motion, useDragControls } from "framer-motion";
import styled from "styled-components";

import { CrossIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { cn } from "@/src/utils/cn";
import { stopPropagation } from "@/src/utils/helpers/MiscHelper";

export const DrawerItem = styled.button.attrs(({ className }) => ({
  className: cn("flex w-full items-center space-x-8 px-18 py-8 disabled:text-gray-40", className),
}))``;

export type DrawerPosition = "right" | "bottom" | "left";

export type DrawerProps = {
  open: boolean;
  className?: ClassValue;
  onOpen?: () => void;
  onClose: (event?: React.SyntheticEvent) => void;
  position?: DrawerPosition;
  title?: JSX.Element | string | null;
  titleClassName?: string;
  fullWidth?: boolean;
  withHeader?: boolean;
  withCloseButton?: boolean;
  backDropStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  animationDuration?: number;
  animationDelay?: number;
  dragToClose?: boolean;
  // handleContainerClassName is additional className to adjust the container of the drawer's handle.
  // A common usage is to adjust the vertical padding of the handle.
  handleContainerClassName?: ClassValue;
  overlayClassName?: string;
};

const MotionContainer = motion(RadixDialog.Content);
const MotionOverlay = motion(RadixDialog.Overlay);

const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = ({
  open,
  className,
  children,
  title,
  titleClassName,
  onClose,
  position = "bottom",
  fullWidth = false,
  withHeader = true,
  withCloseButton = true,
  backDropStyle = {},
  contentStyle = {},
  animationDuration = 0.1,
  animationDelay = 0.05,
  dragToClose = false,
  handleContainerClassName,
  overlayClassName,
}) => {
  const drawerCardRef = useRef<HTMLDivElement>(null);
  const dragControls = useDragControls();

  const _height = useRef(0);
  const startDrag: React.TouchEventHandler<HTMLDivElement> = event => {
    dragControls.start(event);
  };

  const initialAnimation = useMemo(() => {
    if (position === "bottom") {
      return {
        y: "100%",
      };
    }
    if (position === "right") {
      return {
        x: "100%",
      };
    }
    if (position === "left") {
      return {
        x: "-100%",
      };
    }
    return {};
  }, [position]);

  const animateExit = useMemo<AnimationProps["exit"]>(() => {
    if (position === "bottom") {
      return {
        y: "100%",
        opacity: 0,
      };
    }
    if (position === "right") {
      return {
        x: "100%",
      };
    }
    if (position === "left") {
      return {
        x: "-100%",
      };
    }
    return {};
  }, [position]);

  const draggable = position === "bottom" && dragToClose;
  const handleOnClose = (isOpen: boolean) => {
    if (!isOpen) {
      onClose && onClose();
    }
  };

  const dragHandler = (_: unknown, info: PanInfo) => {
    if (info.offset.y > _height.current / 3 || info.offset.y < -300) {
      onClose();
    }
  };

  const calculateHeight = () => {
    if (open) {
      const { height = 0 } = drawerCardRef.current?.getBoundingClientRect() || ({} as DOMRect);
      _height.current = height;
    }
  };

  const handleClickOverlay = (e: SyntheticEvent) => {
    if (position === "bottom") {
      e.stopPropagation();
      onClose();
    }
  };

  return (
    <RadixDialog.Root open={open} onOpenChange={handleOnClose}>
      <AnimatePresence>
        {open && (
          <RadixDialog.Portal forceMount>
            <MotionOverlay
              key="overlay"
              initial={{ opacity: 0.9 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={handleClickOverlay}
              onAnimationEnd={calculateHeight}
              className={cn(
                "fixed inset-0 z-1300 h-screen w-screen bg-black/30",
                "overlay flex items-center justify-center",
                overlayClassName,
              )}
            >
              {withCloseButton && position !== "bottom" && (
                <RadixDialog.Close
                  className={cn("flex", {
                    "fixed right-24 top-24 text-white": position === "left",
                    "fixed left-24 top-24 text-white": position === "right",
                  })}
                >
                  <CrossIcon />
                </RadixDialog.Close>
              )}
            </MotionOverlay>
            <MotionContainer
              key="drawer-body"
              drag={open ? "y" : false}
              dragListener={false}
              dragControls={dragControls}
              // @ts-ignore
              onDragEnd={open ? dragHandler : undefined}
              dragConstraints={{ top: 0 }}
              className={cn(
                "z-1300 w-full bg-white",
                {
                  // TODO: Position other than bottom
                  "bottom-0 left-0 right-0 rounded-t-[15px] px-16 pb-24": position === "bottom",
                  "pt-18": !draggable,
                  "bottom-0 right-0 top-0 max-w-[294px]": position === "right",
                  "bottom-0 left-0 top-0 max-w-[294px]": position === "left",
                  absolute: position === "bottom",
                  fixed: position !== "bottom",
                },
                className,
              )}
              onClick={e => e.nativeEvent.stopImmediatePropagation()}
              ref={drawerCardRef}
              initial={initialAnimation}
              animate={{ x: 0, y: 0 }}
              exit={animateExit}
              transition={{ type: "tween", delay: animationDelay, duration: animationDuration }}
              style={contentStyle}
            >
              {draggable && (
                <div className={cn("py-16", handleContainerClassName)} onTouchStart={startDrag}>
                  <div className="mx-auto h-2 w-24 rounded-full bg-gray-60" />
                </div>
              )}
              {withHeader && (
                <div
                  className={cn("flex w-full items-center justify-between font-medium", {
                    "mb-4": Boolean(title),
                  })}
                >
                  <div className={titleClassName}>{title}</div>
                  {withCloseButton && position === "bottom" && (
                    <RadixDialog.Close>
                      <CrossIcon />
                    </RadixDialog.Close>
                  )}
                </div>
              )}
              <div
                style={{
                  // TODO: Remove -48px after adding paddings to components that consume Drawer
                  // Initially it was added to make the drawer content have consistent paddings
                  // Based on the design, but as the design updated, more components with full width were added
                  maxWidth: fullWidth ? "100%" : `calc(100% - 48px)`,
                }}
                onClick={stopPropagation}
              >
                {children}
              </div>
              <div className="absolute left-0 top-full h-screen w-full bg-white" />
            </MotionContainer>
          </RadixDialog.Portal>
        )}
      </AnimatePresence>
    </RadixDialog.Root>
  );
};

const Separator: React.FC = () => {
  return <hr className="my-8 text-light-gray-100" />;
};

export type DrawerItemProps = {
  Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  label: string;
  onClick?: (e: React.SyntheticEvent) => void;
  disabled?: boolean;
};
const Item: React.FC<DrawerItemProps> = ({ Icon, label, onClick, disabled }) => {
  return (
    <button
      className="flex w-full items-center py-8 text-base text-sembly-gray disabled:text-gray-40"
      onClick={onClick}
      disabled={disabled}
    >
      {Icon && <Icon className="h-20 w-20" />}
      <span className="ml-16">{label}</span>
    </button>
  );
};

Separator.displayName = "Separator";

export const DrawerElm = {
  Separator,
  Item,
};

export default Object.assign(Drawer, DrawerElm);
