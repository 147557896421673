import type { ElementType } from "react";
import { createContext, useCallback, useContext, useMemo, useRef } from "react";

interface Context {
  setEntry: <T>(key: string) => (value: T) => void;
  getEntry: <T>(key: string) => () => T;
  deleteEntry: (key: string) => () => void;
}

const CacheContext = createContext<Context | undefined>(undefined);

export function CacheContextProvider(props: React.PropsWithChildren<unknown>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cache = useRef<Record<string, any>>({});

  const setEntry = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string) => (value: any) => {
      cache.current[key] = value;
    },
    [cache],
  );

  const getEntry = useCallback(
    (key: string) => () => {
      return cache.current[key];
    },
    [cache],
  );

  const deleteEntry = useCallback(
    (key: string) => () => {
      delete cache.current[key];
    },
    [cache],
  );

  return <CacheContext.Provider value={{ setEntry, getEntry, deleteEntry }}>{props.children}</CacheContext.Provider>;
}

type CachePrimaryKey =
  | "sampleKey"
  | "availableResponseTrays"
  | "nodeCardTypeLabel"
  | "flaggingActionModalNodeModel"
  | "useFetchContent"
  | "discussionViewCachedNodeIds"
  | "cachedSpaceIds"
  | "discussionMapPostTypeLabel"
  | "nodeContentTextParsed"
  | "stanceDescriptionLabel";

export function useCache<T>(primaryKey: CachePrimaryKey, secondaryKey?: string) {
  const key = useMemo(() => {
    if (secondaryKey) {
      return `${primaryKey}-${secondaryKey}`;
    }
    return primaryKey;
  }, [primaryKey, secondaryKey]);

  const context = useContext(CacheContext);
  if (context === undefined) {
    throw new Error("useCache must be within CacheContextProvider");
  }

  const { getEntry, setEntry, deleteEntry } = context;

  return {
    getEntry: getEntry<T>(key),
    setEntry: setEntry<T>(key),
    deleteEntry: deleteEntry(key),
  };
}

type LRUCachePrimaryKey = "NodeDisplay.treeData";

export function useLRUCache<T>(primaryKey: LRUCachePrimaryKey, secondaryKey: string, cacheSize: number) {
  const context = useContext(CacheContext);
  if (context === undefined) {
    throw new Error("useLRUCache must be within CacheContextProvider");
  }

  const { getEntry, setEntry } = context;

  const LRUMap = useMemo(() => {
    let cache = getEntry(primaryKey)() as Map<string, T>;
    if (!cache) {
      cache = new Map();
      setEntry(primaryKey)(cache);
    }
    return cache;
  }, [getEntry, setEntry, primaryKey]);

  const get = useCallback(() => {
    const value = LRUMap.get(secondaryKey);
    if (value) {
      LRUMap.delete(secondaryKey);
      LRUMap.set(secondaryKey, value);
    }
    return value;
  }, [LRUMap, secondaryKey]);

  const set = useCallback(
    (value: T) => {
      if (LRUMap.get(secondaryKey)) {
        LRUMap.delete(secondaryKey);
      } else if (LRUMap.size === cacheSize) {
        LRUMap.delete(LRUMap.keys().next().value);
      }
      LRUMap.set(secondaryKey, value);
    },
    [LRUMap, secondaryKey, cacheSize],
  );

  const deleteEntry = useCallback(() => {
    LRUMap.delete(secondaryKey);
  }, [LRUMap, secondaryKey]);

  return {
    getEntry: get,
    setEntry: set,
    deleteEntry,
  };
}

export const withCacheProvider =
  <T,>(Component: ElementType) =>
  // eslint-disable-next-line react/display-name
  (props: T) => (
    <CacheContextProvider>
      <Component {...props} />
    </CacheContextProvider>
  );
