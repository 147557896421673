import React from "react";

import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";

import chat from "@/src/translations/en/chat.json";
import coachmarkENUS from "@/src/translations/en/coachmark.json";
import commonENUS from "@/src/translations/en/common.json";
import constantsENUS from "@/src/translations/en/constants.json";
import faqsENUS from "@/src/translations/en/faqs.json";
import guestPreview from "@/src/translations/en/guest-preview.json";
import landingpage from "@/src/translations/en/landingpage.json";
import navigation from "@/src/translations/en/navigation.json";
import space from "@/src/translations/en/space.json";
import timestampENUS from "@/src/translations/en/timestamp.json";
import toolbarENUS from "@/src/translations/en/toolbar.json";
import tutorialENUS from "@/src/translations/en/tutorial.json";
import user from "@/src/translations/en/user.json";
import validationENUS from "@/src/translations/en/validation.json";
import commonFR from "@/src/translations/fr/common.json";

/*
 * DESCRIPTION
 * Wraps component with i18next provider component
 *
 * EXTERNAL DEPENDENCIES
 * - React i18next
 *   https://www.npmjs.com/package/react-i18next
 */

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en",
  fallbackLng: "en",
  resources: {
    en: {
      validation: validationENUS,
      common: commonENUS,
      timestamp: timestampENUS,
      constants: constantsENUS,
      coachmark: coachmarkENUS,
      faqs: faqsENUS,
      tutorial: tutorialENUS,
      landingpage,
      toolbar: toolbarENUS,
      guestPreview,
      space,
      navigation,
      chat,
      user,
    },
    fr: {
      common: commonFR,
    },
  },
});

export const withI18NProvider = Component => {
  const WrappedComponent = props => {
    return (
      <I18nextProvider i18n={i18next}>
        <Component {...props} />
      </I18nextProvider>
    );
  };

  WrappedComponent.displayName = `withI18NProvider(${Component.displayName || Component.name})`;

  return WrappedComponent;
};

export default i18next;
