import type { FunctionComponent } from "react";
import React, { useContext, useMemo } from "react";

import useMedia from "@/src/hooks/useMedia";
import { usePageComponentSize } from "@/src/stores/dynamic/pageComponentSize";

// These following value are calculated based on a formula
// See this for reference: https://github.com/hiveos-team/hiveos-frontend/pull/2412

const VIEW_RATIO = 0.25;
const MIN_CONTENT_WIDTH = 600;

const PADDING_DEFAULT = 0;
const PADDING_TABLET = 0;
const PADDING_MOBILE = 0;

const INNER_VIEW_RATIO = 0.12;
const INNER_MIN_CONTENT_WIDTH = 467;

const INNER_PADDING_DEFAULT = 16;
const INNER_PADDING_TABLET = 8;
const INNER_PADDING_MOBILE = 8;

interface SpaceContentPadding {
  padding?: number;
  innerPadding?: number;
  paddingStyle?: {
    paddingLeft: string;
    paddingRight: string;
  };
  innerPaddingStyle?: {
    paddingLeft: string;
    paddingRight: string;
  };
}

const SpaceContentPaddingContext = React.createContext<SpaceContentPadding | undefined>(undefined);

export function SpaceContentPaddingProvider({ children }: React.PropsWithChildren<unknown>) {
  const { matchesTablet } = useMedia();
  const feedWidth = usePageComponentSize(p => p.feedWidth);

  const padding = useMemo(() => {
    if (feedWidth) {
      return Math.max(Math.floor(VIEW_RATIO * (feedWidth - MIN_CONTENT_WIDTH)), PADDING_DEFAULT);
    } else {
      return matchesTablet ? PADDING_TABLET : PADDING_MOBILE;
    }
  }, [feedWidth, matchesTablet]);

  const innerPadding = useMemo(() => {
    if (feedWidth) {
      return Math.max(Math.floor(INNER_VIEW_RATIO * (feedWidth - INNER_MIN_CONTENT_WIDTH)), INNER_PADDING_DEFAULT);
    } else {
      return matchesTablet ? INNER_PADDING_TABLET : INNER_PADDING_MOBILE;
    }
  }, [feedWidth, matchesTablet]);

  const values = useMemo(
    () => ({
      padding,
      paddingStyle: { paddingLeft: `${padding}px`, paddingRight: `${padding}px` },
      innerPadding,
      innerPaddingStyle: { paddingLeft: `${innerPadding}px`, paddingRight: `${innerPadding}px` },
    }),
    [padding, innerPadding],
  );

  return <SpaceContentPaddingContext.Provider value={values}>{children}</SpaceContentPaddingContext.Provider>;
}

export function withSpaceContentPaddingProvider<T extends JSX.IntrinsicAttributes>(Component: FunctionComponent<T>) {
  const WrappedComponent: FunctionComponent<T> = (props: T) => (
    <SpaceContentPaddingProvider>
      <Component {...props} />
    </SpaceContentPaddingProvider>
  );

  WrappedComponent.displayName = `withSpaceContentPaddingProvider(${Component.displayName || Component.name})`;

  return WrappedComponent;
}

export function useSpaceContentPadding() {
  const context = useContext(SpaceContentPaddingContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useSpaceContentPadding must be used within a SpaceContentPaddingProvider. Did you forget to wrap your page or component with SpaceContentPaddingProvider? ",
    );
  }

  return context;
}
