import type { FunctionComponent, SyntheticEvent } from "react";
import React, { useCallback, useContext, useRef } from "react";
import { useState } from "react";

import * as RadixTooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";

import type { TooltipContentProps as TooltipBaseContentProps } from "@/src/components/sembly-ui/components/TooltipContent/TooltipContent";
import TooltipBaseContentContainer from "@/src/components/sembly-ui/components/TooltipContent/TooltipContent";

const CloseTooltipContext = React.createContext<() => void>(null!);

interface TooltipRootProps extends RadixTooltip.TooltipProps {
  showTooltip?: boolean;
}

export type TooltipHandle = {
  hide: () => void;
};

const TooltipRoot = (props: TooltipRootProps, ref: React.ForwardedRef<TooltipHandle>) => {
  const { open, onOpenChange, delayDuration, showTooltip = true, children, ...rest } = props;
  const [isInternalOpen, toggleInternalOpen] = useState(Boolean(open));

  React.useImperativeHandle(ref, () => ({
    hide() {
      toggleInternalOpen(false);
    },
  }));

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      toggleInternalOpen(isOpen);
      onOpenChange && onOpenChange(isOpen);
    },
    [onOpenChange],
  );

  const closeTooltip = useCallback(() => handleOpenChange(false), [handleOpenChange]);

  let renderedChildren = children;
  if (!showTooltip) {
    renderedChildren = React.Children.toArray(children).filter(
      child => React.isValidElement(child) && child.type !== TooltipContent && child.type !== DefaultTooltipContent,
    );
  }

  return (
    <CloseTooltipContext.Provider value={closeTooltip}>
      <RadixTooltip.Root open={isInternalOpen} onOpenChange={handleOpenChange} delayDuration={delayDuration} {...rest}>
        {renderedChildren}
      </RadixTooltip.Root>
    </CloseTooltipContext.Provider>
  );
};

interface TooltipTriggerProps extends RadixTooltip.TooltipTriggerProps {
  /** @todo: Implement cursor coordinate. Temporarilty skipped to make migration quicker and unblock things */
  useCursorCoordinate?: boolean;
}

/**
 * Please note that this component wraps the children with <button> tag.
 * By accessibility semantic, the tooltrip trigger must be a focusable element such as button or a
 * so that it can be accessed through keyboard navigation.
 *
 * If the children provided here is already a focusable element, we can skip wrapping it with button
 * by passing a truthy `asChild` props.
 * */
const TooltipTrigger = (props: TooltipTriggerProps) => {
  const { onClick, ...rest } = props;
  const triggerRef = useRef<HTMLButtonElement>(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    onClick && onClick(e);

    // Neccessary to handle this specific case:
    // - The tooltip has clickable content
    // - User click trigger to dismiss tooltip
    // - The document focused on trigger, unable to click action inside tooltip
    // By forcing the trigger to not have focus, we still enable the tooltip action clicking.
    // Not using e.target because the captured target might be the element inside the trigger.
    triggerRef.current?.blur();
  };

  return <RadixTooltip.Trigger type="button" ref={triggerRef} onClick={handleClick} {...rest} />;
};

interface TooltipContentProps extends RadixTooltip.TooltipContentProps {
  hasClickableContent?: boolean;
  withPortal?: boolean;
  withAppearAnimation?: boolean;
}

const TooltipContent = (props: TooltipContentProps) => {
  const { className, hasClickableContent, withPortal = true, withAppearAnimation, forceMount, ...rest } = props;
  const closeTooltip = useContext(CloseTooltipContext);

  const handleClickToCloseTooltip = (e: SyntheticEvent) => {
    if (hasClickableContent) {
      e.stopPropagation();
      e.preventDefault();
      closeTooltip && closeTooltip();
    }
  };

  const contentProps = {
    className: clsx("z-tooltip", { "animate-popover-appear": withAppearAnimation }, className),
    onClick: handleClickToCloseTooltip,
    ...rest,
  };

  return withPortal ? (
    <RadixTooltip.Portal forceMount={forceMount}>
      <RadixTooltip.Content onPointerDownOutside={e => e.preventDefault()} {...contentProps} />
    </RadixTooltip.Portal>
  ) : (
    <RadixTooltip.Content {...contentProps} />
  );
};

export const DefaultTooltipContent = (props: TooltipContentProps & TooltipBaseContentProps) => {
  const { variant, className, children, sideOffset = 4, ...rest } = props;
  return (
    <TooltipContent sideOffset={sideOffset} {...rest}>
      <TooltipBaseContentContainer className={className} variant={variant}>
        {children}
      </TooltipBaseContentContainer>
    </TooltipContent>
  );
};
export function withTooltipProvider<T extends JSX.IntrinsicAttributes>(Component: FunctionComponent<T>) {
  const WrappedComponent: FunctionComponent<T> = (props: T) => (
    <RadixTooltip.Provider delayDuration={200} skipDelayDuration={0}>
      <Component {...props} />
    </RadixTooltip.Provider>
  );
  WrappedComponent.displayName = `withSpaceContentPaddingProvider(${Component.displayName || Component.name})`;

  return WrappedComponent;
}

/**
 * Not everything hover-to-show is a tooltip.
 * Consider using HoverCard if your tooltip content is very complex and not meant to label the hovered element.
 **/
export default {
  Root: React.forwardRef(TooltipRoot),
  Trigger: TooltipTrigger,
  Content: TooltipContent,
  DefaultContent: DefaultTooltipContent,
  Provider: RadixTooltip.TooltipProvider,
};
