import { useCallback, useState } from "react";

type FlagAsObject = {
  flag: boolean;
  setFlag: (state: boolean) => void;
  setOn: () => void;
  setOff: () => void;
  toggle: () => void;
};

type FlagAsArray = [boolean, () => void, () => void, () => void, () => void];
type Flag = FlagAsArray & FlagAsObject;

const useFlag = (initialState = false) => {
  const [flag, setFlag] = useState(!!initialState);

  const setOn = useCallback(() => {
    setFlag(true);
  }, []);
  const setOff = useCallback(() => {
    setFlag(false);
  }, []);
  const toggle = useCallback(() => {
    setFlag(state => !state);
  }, []);

  const flagObject: FlagAsArray & FlagAsObject = [flag, setOn, setOff, setFlag as () => void, toggle] as Flag;

  flagObject.flag = flag;
  flagObject.setFlag = setFlag as (state: boolean) => void;
  flagObject.setOn = setOn;
  flagObject.setOff = setOff;
  flagObject.toggle = toggle;

  return flagObject;
};
export default useFlag;
