/* eslint-disable jsx-a11y/alt-text */
import type { ImageProps } from "next/image";
import Image from "next/image";

type ImageLoaderProps = {
  src: string;
  width?: number;
  quality?: number;
  aspectRatio?: `${number}-${number}`;
};

const IMAGEKIT_ENDPOINT = "https://ik.imagekit.io/8gceidoqid";

const videoThumbnailAssetPath = "ik.imagekit.io/8gceidoqid/multimedia-test/video";

export const isImageKitUrl = (src: string) => {
  return typeof src === "string" && src.includes("imagekit.io");
};

export const imageKitLoader = ({ src, width, quality = 80 }: ImageLoaderProps, imageKitOptions?: string[]) => {
  if (src[0] === "/") src = src.slice(1);
  const params = [];
  if (width) {
    params.push([`w-${width}`]);
  }

  if (quality) {
    params.push(`q-${quality}`);
  }

  // Image kit Video thumbnail, does not support progressive image and blur transformation
  // Exclude transformation for now
  if (!src.includes(videoThumbnailAssetPath)) {
    params.push("pr-true");
  }

  if (imageKitOptions) {
    imageKitOptions.forEach(data => params.push(data));
  }

  const paramsString = params.join(",");
  const path = src.replace(`${IMAGEKIT_ENDPOINT}/`, "");

  return `${IMAGEKIT_ENDPOINT}/${path}?tr=${paramsString}`;
};

const generateImageKitBlurDataUrl = (
  src: string,
  width?: number | string,
): { placeholder: "blur"; blurDataURL: string } | {} => {
  // if image is smaller then 40, no need blur
  if (width && Number(width) < 40) return {};

  // Image kit Video thumbnail, does not support progressive image and blur transformation
  // Exclude transformation for now
  if (!src.includes(videoThumbnailAssetPath)) {
    const params = [];
    if (width) {
      params.push(`w-${width}`);
    }

    params.push(`bl-6`);
    const paramsString = params.join(",");
    return {
      placeholder: "blur",
      blurDataURL: `${src}?tr=${paramsString}`,
    };
  }

  // Video thumbnail will serve 20x20 image placeholder
  return {
    placeholder: "blur",
    blurDataURL: `${src}?tr=w-20,h-20`,
  };
};

interface SemblyImageProps extends ImageProps {
  imageKitOptions?: string[];
  blurInitially?: boolean;
}

const SemblyImage = ({ imageKitOptions, blurInitially = true, ...props }: SemblyImageProps) => {
  if (isImageKitUrl(props?.src as string)) {
    // Remove BE querystring to respect next image logic and imagekit loader
    const imageUrl = (props.src as string).split("?")[0];

    const blurDataProps = blurInitially ? generateImageKitBlurDataUrl(imageUrl, props.width) : {};

    const imageProps = {
      ...props,
      ...blurDataProps,
      src: imageUrl,
    };

    return (
      <Image
        {...imageProps}
        loader={({ src, width, quality }) => imageKitLoader({ src, width, quality }, imageKitOptions)}
      />
    );
  }
  return <Image {...props} />;
};

export default SemblyImage;
