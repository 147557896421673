import type { MouseEventHandler, SyntheticEvent } from "react";
import React, { memo, useRef } from "react";

import type { TooltipContentProps } from "@radix-ui/react-tooltip";

import Drawer from "@/src/components/sembly-ui/components/Drawer/Drawer";
import type { TooltipHandle } from "@/src/components/sembly-ui/components/Tooltip/Tooltip";
import Tooltip from "@/src/components/sembly-ui/components/Tooltip/Tooltip";
import TooltipContent from "@/src/components/sembly-ui/components/TooltipContent/TooltipContent";
import useFlag from "@/src/hooks/useFlag";
import useMedia from "@/src/hooks/useMedia";

export interface LabelTooltipProps {
  // `content` is a component used for both the drawer content in the mobile view AND the tooltip content in the desktop view.
  // When `content` props is undefined, `tooltipContent` is used as a fallback for tooltip content in the desktop view.
  content?: JSX.Element | string | React.ReactNode;
  /** @deprecated As an effort to standardise the tooltip styling, please avoid using this props.
   * Use `content` props whenever possible.
   *
   * `tooltipContent` is a component used as a fallback for the tooltip in the desktop view. Overriden by `content` props.
   * */
  tooltipContent?: JSX.Element | string | React.ReactNode;
  className?: string | undefined;
  showTooltip?: boolean;
  tooltipAlign?: TooltipContentProps["align"];
  tooltipAlignOffset?: number;
  tooltipSideOffset?: number;
  tooltipSide?: TooltipContentProps["side"];
  tooltipClassName?: string;
  drawerTitle?: JSX.Element | string;
  openDrawerAdditionalAction?: () => void;
  closeDrawerAdditionalAction?: () => void;
  withDrawerCloseButton?: boolean;
  forceMountTooltip?: boolean;
  withPortal?: boolean;
}

const LabelTooltip: React.FC<React.PropsWithChildren<LabelTooltipProps>> = ({
  children,
  content,
  tooltipContent,
  className,
  tooltipClassName,
  drawerTitle,
  tooltipAlign,
  tooltipAlignOffset,
  tooltipSideOffset = 4,
  tooltipSide = "bottom",
  showTooltip = true,
  openDrawerAdditionalAction,
  closeDrawerAdditionalAction,
  withDrawerCloseButton = false,
  forceMountTooltip,
  withPortal = true,
}) => {
  const { matchesMobile } = useMedia();
  const tooltipRef = useRef<TooltipHandle>(null);
  const [isDescriptionDrawerOpen, openDescriptionDrawer, closeDescriptionDrawer] = useFlag();

  const handleOpenDescriptionDrawer: MouseEventHandler<HTMLDivElement> = e => {
    e?.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent?.stopImmediatePropagation();
    openDrawerAdditionalAction?.();

    if (content && showTooltip) {
      openDescriptionDrawer();
    }
  };

  const handleCloseDescriptionDrawer = (e?: SyntheticEvent<EventTarget>) => {
    e?.stopPropagation();
    closeDescriptionDrawer();
    closeDrawerAdditionalAction?.();
  };

  const handleClickOnTooltipContent = (e: SyntheticEvent) => {
    e.stopPropagation();
    tooltipRef.current?.hide();
  };

  return (
    <>
      {!matchesMobile && (
        <Tooltip.Root ref={tooltipRef} showTooltip={showTooltip}>
          <Tooltip.Trigger className={className}>{children}</Tooltip.Trigger>
          <Tooltip.Content
            onClick={handleClickOnTooltipContent}
            sideOffset={tooltipSideOffset}
            alignOffset={tooltipAlignOffset}
            hasClickableContent
            align={tooltipAlign}
            side={tooltipSide}
            className={tooltipClassName}
            forceMount={forceMountTooltip || undefined}
            withPortal={withPortal}
          >
            <TooltipContent>{content ?? tooltipContent}</TooltipContent>
          </Tooltip.Content>
        </Tooltip.Root>
      )}

      {matchesMobile && (
        <div role="button" className={className} onClick={handleOpenDescriptionDrawer}>
          {children}
        </div>
      )}

      {isDescriptionDrawerOpen && matchesMobile && (
        <Drawer
          open={isDescriptionDrawerOpen}
          onClose={handleCloseDescriptionDrawer}
          title={drawerTitle}
          className="!bg-sembly-gray !py-16 !text-white"
          withCloseButton={withDrawerCloseButton}
          withHeader={Boolean(drawerTitle)}
          fullWidth
        >
          {content}
        </Drawer>
      )}
    </>
  );
};

export default memo(LabelTooltip);
