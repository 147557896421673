import React from "react";

import { cn } from "@/src/utils/cn";

type TooltipContentVariant = "default" | "white";

export interface TooltipContentProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: TooltipContentVariant;
  className?: string;
}

function TooltipContent({ children, variant = "default", className, ...props }: TooltipContentProps) {
  const containerClass = cn([
    "max-w-[200px] rounded-[5px] px-8 py-4 text-xs",
    {
      "bg-white text-gray-80 shadow-md": variant === "white",
      "bg-sembly-gray text-beige-60": variant === "default",
    },
    className,
  ]);

  return (
    <div className={containerClass} {...props}>
      {children}
    </div>
  );
}

TooltipContent.displayName = "TooltipContent";
export default TooltipContent;
