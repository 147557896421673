import { useCallback } from "react";

import { useHistory } from "react-router";

import {
  ROUTER_PATH_SPACE_WITH_PRESENTATION,
  ROUTER_PATH_SPACE_WITH_PRESENTATION_AND_HIGHLIGHT,
  SPACE_PRESENTATION,
} from "@/src/constants/AppConstant";
import useGenericTracking, { TRACKING_EVENT } from "@/src/hooks/internal-tracking/useGenericTracking";
import { getCleanNodeId } from "@/src/pages/SpaceLandingPage/utils/SpaceSectionListHelper";
import parseTemplateString from "@/src/utils/helpers/parseTemplateString";

interface Props {
  spaceUrl: string;
  highlightedId?: string;
  withTracking?: boolean;
  openNewTab?: boolean;
}

const useSpaceNavigation = () => {
  const history = useHistory();
  const trackingFetcher = useGenericTracking();

  const navigateToSpaceSection = useCallback(
    ({ spaceUrl, highlightedId, withTracking, openNewTab }: Props) => {
      let url: string;
      if (highlightedId) {
        const cleanNodeId = getCleanNodeId(highlightedId);
        if (withTracking) {
          trackingFetcher(TRACKING_EVENT.SECTION_VISIT, { nodeId: String(cleanNodeId) });
        }
        url = parseTemplateString(ROUTER_PATH_SPACE_WITH_PRESENTATION_AND_HIGHLIGHT, {
          spaceId: spaceUrl,
          presentation: SPACE_PRESENTATION.ALL,
          highlightId: cleanNodeId,
        });
      } else {
        url = parseTemplateString(ROUTER_PATH_SPACE_WITH_PRESENTATION, {
          spaceId: spaceUrl,
          presentation: SPACE_PRESENTATION.ALL,
        });
      }
      if (openNewTab) {
        window.open(`${window.location.origin}${url}`, "_blank");
      } else {
        history.push(url);
      }
    },
    [history, trackingFetcher],
  );

  return { navigateToSpaceSection };
};

export default useSpaceNavigation;
