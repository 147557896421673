import type { CSSProperties, ComponentProps } from "react";
import React, { useCallback, useMemo, useRef, useState } from "react";

import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import htmr from "htmr";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@/src/components/sembly-ui/core/BaseComponent/Dialog/Dialog";
import { ChevronFilledUpIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import Image from "@/src/components/sembly-ui/core/BaseComponent/Image/Image";
import useZendesk from "@/src/hooks/useZenDesk";
import { isOpenedSelector, topicSelector } from "@/src/stores/tutorialCard/selectors";
import type { topic } from "@/src/stores/tutorialCard/slice";
import { TUTORIAL_CARD_TOPIC, tutorialCardSlice } from "@/src/stores/tutorialCard/slice";

const MAIN_TUTORIAL_CONTAINER = "mainTutorialContainer";
const FIRST_TOPIC_CARD = "firstTopicCard";

const ONBOARDING_CARD_TOP = 60;
const ANIMATION_DURATION = 0.5; // second
const IMAGE_HEIGHT = 362; // px
const IMAGE_WIDTH = 644; // px

const ONBOARDING_CARDS: string[] = [
  TUTORIAL_CARD_TOPIC.CONTRIBUTE_EXISTING_SPACE,
  TUTORIAL_CARD_TOPIC.ENGAGE_IN_STRUCTURED_COLLABORATION,
  TUTORIAL_CARD_TOPIC.PARTICIPATE_COLLECTIVE_GOVERNANCE,
];

function isOnboardingCards(topicName: string) {
  return ONBOARDING_CARDS.includes(topicName);
}

interface TopicAnchor {
  name: string;
  parentId: string;
  anchor: HTMLSpanElement;
  top: number;
  left: number;
}

interface TopicButtonProps {
  text: string;
  name: topic;
  parentId: string;
  mainTopic: string;
  topicIndex: number;
  setTopics: React.Dispatch<React.SetStateAction<TopicAnchor[]>>;
  isOverviewCard?: boolean;
  style?: CSSProperties;
}

const TopicButton = ({
  text,
  name,
  parentId,
  mainTopic,
  topicIndex,
  isOverviewCard,
  setTopics,
  style,
}: TopicButtonProps) => {
  const buttonRef = useRef<HTMLSpanElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      setTopics(topics => {
        const siblingIndex = topics.findIndex(topic => topic.name !== name && topic.parentId === parentId);
        const isOpened = topicIndex !== -1;
        const isSibling = siblingIndex !== -1;

        const mainContainerElm = document.getElementById(MAIN_TUTORIAL_CONTAINER) as HTMLDivElement;
        const mainRect = mainContainerElm.getBoundingClientRect();
        const buttonRect = buttonRef.current!.getBoundingClientRect();

        const isOnboarding = isOverviewCard && isOnboardingCards(name);
        let top = 0;
        let left = 0;
        if (isOnboarding) {
          // top and left of the first card. This will be added with a fixed distance times the index
          const firstCard = document.getElementById(FIRST_TOPIC_CARD) as HTMLDivElement;
          const firstCardRect = firstCard.getBoundingClientRect();
          top = firstCardRect.top - mainRect.top;
          left = firstCardRect.left;
        } else {
          // top and left relative to the main container element to the clicked topic button
          top = buttonRect.top + buttonRect.height - mainRect.top;
          left = buttonRect.left + buttonRect.width / 2 - mainRect.left;
        }

        if (isOpened) {
          // close the dialog
          return topics.slice(0, topicIndex).filter(topic => topic.parentId !== parentId);
        }

        const previousSameTopicIndex = topics.findIndex(topic => topic.name === name);
        const isPreviousSameTopicExist = (mainTopic === name && topics.length > 0) || previousSameTopicIndex !== -1;

        if (isPreviousSameTopicExist) {
          // just keep until the topic dialog that is same with clicked topic
          return [...topics.slice(0, previousSameTopicIndex + 1)];
        }
        // check if it's sibling (another topic that belong in the same dialog) is opened
        if (isSibling) {
          // open the dialog + close sibling topic and it's descendant(s)
          return [
            ...topics.slice(0, siblingIndex),
            { name: name, parentId: parentId, anchor: buttonRef.current!, top, left },
          ];
        } else {
          // open the dialog
          return [...topics, { name: name, parentId: parentId, anchor: buttonRef.current!, top, left }];
        }
      });
    },
    [setTopics, topicIndex, isOverviewCard, name, mainTopic, parentId],
  );

  return (
    <>
      {" "}
      <button
        style={style}
        className={clsx([
          "rounded transition-colors duration-500",
          topicIndex === -1 && "hover:bg-light-gray-40",
          topicIndex !== -1 && `bg-medium-purple-20 text-medium-purple-100`,
        ])}
        onClick={handleClick}
      >
        <span
          data-text={text}
          ref={buttonRef}
          className="-mb-1 inline-grid cursor-pointer select-none border-b-[1.5px] border-dashed border-b-medium-purple-100 leading-5 transition-all duration-500 after:invisible after:block after:h-0 after:overflow-hidden after:content-[attr(data-text)]"
        >
          {text}
        </span>
      </button>{" "}
    </>
  );
};

const HelpDeskButton = (props: ComponentProps<"button">) => {
  const { children, ...rest } = props;
  return (
    <button className={clsx(["rounded transition-colors duration-500 hover:bg-light-gray-40"])} {...rest}>
      <span className="-mb-1 inline-grid cursor-pointer select-none border-b-[1.5px] border-dashed border-b-medium-purple-100 leading-5 transition-all duration-500 after:invisible after:block after:h-0 after:overflow-hidden after:content-[attr(data-text)]">
        {children}
      </span>
    </button>
  );
};

type TopicContent = string | { type: "image" | "video"; src: string[] };

interface TopicDialogProps {
  index: number;
  topics: TopicAnchor[];
  setTopics: React.Dispatch<React.SetStateAction<TopicAnchor[]>>;
  isActive: boolean;
  renderRow: (template: TopicContent, parentId: string, isFirst: boolean) => void;
  top: number;
  left: number;
  isOverviewCard: boolean;
}

const TopicDialog = ({
  index,
  topics,
  setTopics,
  isActive,
  renderRow,
  top,
  left,
  isOverviewCard,
}: TopicDialogProps) => {
  const [t] = useTranslation("tutorial");
  const ref = useRef<HTMLDivElement>(null);
  const id = useMemo(() => Math.random().toString(), []); // to identify are some topics is sibling
  const topic = topics[index];

  const actionDialogClicked = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (index < topics.length - 1) {
      // close dialog(s) that in front of this current dialog
      setTopics(topics => topics.slice(0, index + 1));
    }
  };

  const isOnboarding = isOverviewCard && isOnboardingCards(topic.name);
  const appliedTop = isOnboarding ? top + (index + 1) * ONBOARDING_CARD_TOP : top;

  const assets = t(`topics.${topic.name}.contents`, { returnObjects: true }) as TopicContent[];

  return (
    <motion.div
      ref={ref}
      initial={{ y: -64, top: appliedTop, opacity: 0 }}
      animate={{ y: 0, top: appliedTop, opacity: 1 }}
      transition={{ duration: ANIMATION_DURATION }}
      exit={{ y: -64, top: appliedTop, opacity: 0 }}
      className={clsx(["absolute left-0 w-full", isActive ? "cursor-auto" : "cursor-pointer"])}
      onClick={actionDialogClicked}
    >
      {!isOnboarding && (
        <ChevronFilledUpIcon
          className={clsx([
            `transition-colors duration-500`,
            {
              "text-white": isActive,
              "text-light-gray-40": !isActive,
            },
          ])}
          style={{ marginLeft: left }}
        />
      )}
      <div
        className={clsx([
          "mx-auto rounded-3xl bg-white p-24 text-sembly-gray shadow-light transition-all duration-500",
          isActive ? "mb-32" : "brightness-95",
        ])}
      >
        <div className="text-base">
          {/* @ts-ignore */}
          {typeof assets === "object" &&
            assets?.map((template: TopicContent, index: number) => renderRow(template, id, index === 0))}
        </div>
      </div>
    </motion.div>
  );
};

const TutorialCardDialog = () => {
  const dispatch = useDispatch();
  const handleOpenZendeskWidget = useZendesk();
  const isOpened = useSelector(isOpenedSelector);
  const mainTopic = useSelector(topicSelector);
  const isOverviewCard = mainTopic === TUTORIAL_CARD_TOPIC.WELCOME_TO_SEMBLY;
  const [t] = useTranslation("tutorial");
  const containerRef = useRef<HTMLDivElement>(null);

  const [topics, setTopics] = useState<TopicAnchor[]>([]);

  const renderRow = (template: TopicContent, parentId: string, isFirst: boolean) => {
    if (typeof template === "string") {
      const texts = template.split(/{|}/);

      const parsedTexts = texts.map((text, idx) => {
        return (
          <span key={`${parentId}-${idx}`}>
            {htmr(text, {
              transform: {
                a: ({ children, ...rest }: ComponentProps<"a">) => {
                  return (
                    <a {...rest} className="text-medium-purple-100 underline" target="_blank" rel="noreferrer">
                      {children}
                    </a>
                  );
                },
                strong: ({ children, ...rest }: ComponentProps<"strong">) => {
                  return (
                    <strong {...rest} className="font-bold text-sembly-gray">
                      {children}
                    </strong>
                  );
                },
                span: ({
                  children,
                  ...rest
                }: ComponentProps<"button"> & { "data-type"?: string; "data-topic"?: string }) => {
                  if (rest && rest?.["data-type"] === "zendesk") {
                    return (
                      <HelpDeskButton
                        {...rest}
                        onClick={() => {
                          handleOpenZendeskWidget();
                        }}
                      >
                        {children}
                      </HelpDeskButton>
                    );
                  } else if (rest?.["data-topic"]) {
                    const topicName = rest["data-topic"] as string;
                    const topicIndex = topics.findIndex(
                      topic => topic.name === topicName && topic.parentId === parentId,
                    );
                    return (
                      <TopicButton
                        text={children as string}
                        name={topicName as topic}
                        parentId={parentId}
                        mainTopic={mainTopic || ""}
                        topicIndex={topicIndex}
                        isOverviewCard={isOverviewCard}
                        setTopics={setTopics}
                        style={rest.style}
                      />
                    );
                  }
                  return <span {...rest}>{children}</span>;
                },
              },
            })}
          </span>
        );
      });

      return <p className={clsx("prose max-w-none leading-6", { "mt-24": !isFirst })}>{parsedTexts}</p>;
    } else {
      if (template.type === "video") {
        return template.src.map((source, index) => (
          <video
            autoPlay
            loop
            className={clsx(["aspect-video h-auto w-full max-w-full", { "mt-24": !isFirst }])}
            src={source}
            key={index}
          />
        ));
      } else if (template.type === "image") {
        return (
          <div className={clsx({ "mt-24": !isFirst })}>
            {template.src.map((source, index) => (
              <Image height={IMAGE_HEIGHT} width={IMAGE_WIDTH} src={source} alt="" key={index} />
            ))}
          </div>
        );
      }
    }
  };

  const actionFirstCardClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setTopics([]);
  };

  const actionClose = () => {
    setTopics([]);
    dispatch(tutorialCardSlice.actions.close());
  };

  return (
    <>
      {isOpened && (
        <Dialog
          open={isOpened}
          onClose={actionClose}
          overlayStyle={{ zIndex: 1301, overflowY: "scroll", alignItems: "flex-start" }}
        >
          <Dialog.Container
            ref={containerRef}
            id={MAIN_TUTORIAL_CONTAINER}
            className="relative flex min-h-full w-11/12 max-w-[692px] items-center !bg-transparent !shadow-none md:w-full [&::-webkit-scrollbar]:hidden"
            onClick={actionClose}
          >
            <AnimatePresence>
              <motion.div
                key={mainTopic}
                id={FIRST_TOPIC_CARD}
                initial={{ top: 500, opacity: 0 }}
                animate={{ top: 0, opacity: 1 }}
                transition={{ duration: ANIMATION_DURATION }}
                exit={{ top: 500, opacity: 0 }}
                className={clsx([
                  "w-full rounded-3xl p-24 text-sembly-gray shadow-light transition-colors duration-500",
                  topics.length === 0 ? "cursor-auto bg-white" : "cursor-pointer bg-light-gray-40",
                ])}
                onClick={actionFirstCardClicked}
              >
                <div className="prose max-w-none text-base">
                  {/* @ts-ignore */}
                  {t(`topics.${mainTopic}.contents`, { returnObjects: true }).map(
                    (template: TopicContent, index: number) => renderRow(template, `${0}`, index === 0),
                  )}
                </div>
                <AnimatePresence>
                  {topics.map(({ name, parentId, top, left }, index) => {
                    const isActive =
                      topics[topics.length - 1].name === name && topics[topics.length - 1].parentId === parentId;

                    return (
                      <TopicDialog
                        key={`${name}-${parentId}`}
                        index={index}
                        topics={topics}
                        setTopics={setTopics}
                        top={top}
                        left={left}
                        isActive={isActive}
                        renderRow={renderRow}
                        isOverviewCard={isOverviewCard}
                      />
                    );
                  })}
                </AnimatePresence>
              </motion.div>
            </AnimatePresence>
          </Dialog.Container>
        </Dialog>
      )}
    </>
  );
};

export default TutorialCardDialog;
