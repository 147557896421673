import React, { useMemo } from "react";

import clsx from "clsx";
import styled from "styled-components";

import { LockBadgeIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import SemblyImage from "@/src/components/sembly-ui/core/BaseComponent/Image/Image";
import { generateSpaceColor } from "@/src/utils/helpers/ColorHelper";

// Based on design system https://www.figma.com/file/C7TLLuNBlkq5VzU6IDADVf/%F0%9F%8C%90-General-2022?type=design&node-id=3560-7197&mode=dev

export type IconVariant = "xs" | "sm-xs" | "sm" | "md" | "lg" | "xl" | "xxl";
type BaseSpaceIconProps = {
  /**
   * - `xs` : 16px
   * - `sm-xs` : 20px
   * - `sm` : 24px
   * - `md` : 32px
   * - `lg` : 40px
   * - `xl` : 64px
   * - `xxl` : 96px
   */
  variant?: IconVariant;
  spaceName?: string;
  spaceUrl?: string;
  spaceImageUrl?: string;
  progress?: number; // Range (0-100) to render circular
  children?: React.ReactNode;
};

const spaceIconSize = {
  xs: 16,
  "sm-xs": 20,
  sm: 24,
  md: 32,
  lg: 40,
  xl: 64,
  xxl: 96,
} as Record<IconVariant, number>;

function getFontSizeClass(variant: IconVariant) {
  switch (variant) {
    case "xs":
    case "sm-xs":
      return "text-2xs font-medium";
    case "sm":
      return "text-xs font-medium";
    case "md":
    case "lg":
      return "text-base font-bold";
    case "xl":
      return "text-xl font-bold";
    case "xxl":
      return "text-3xl font-bold";
  }
}

function getAvatarSizeClass(variant: IconVariant) {
  switch (variant) {
    case "xs":
      return "size-16";
    case "sm-xs":
      return "size-20";
    case "sm":
      return "size-24";
    case "md":
      return "size-32";
    case "lg":
      return "size-40";
    case "xl":
      return "size-64";
    case "xxl":
      return "size-[96px]";
  }
}

interface ProgressLayerProps {
  completeDegree: number;
  className?: string;
}
const ProgressLayer = styled.div.attrs(({ className }: ProgressLayerProps) => ({
  // Inner border radius is 20 - padding which is 2
  className: clsx("rounded-[18%]", className),
}))<ProgressLayerProps>`
  ${({ completeDegree }) =>
    `background: conic-gradient(transparent ${completeDegree}deg, rgba(255, 255, 255, .75) ${completeDegree}deg)`};
`;

function IconContainer({
  children,
  variant,
  className,
  ...rest
}: {
  children: React.ReactNode;
  variant: IconVariant;
  className?: string;
}) {
  const sizeClass = getAvatarSizeClass(variant);
  const fontClass = getFontSizeClass(variant);

  return (
    <div
      className={clsx("relative overflow-hidden rounded-[20%] bg-white text-white ", [sizeClass, fontClass, className])}
      {...rest}
    >
      {children}
    </div>
  );
}

function BaseSpaceIcon({
  variant = "lg",
  spaceImageUrl,
  spaceName,
  progress,
  spaceUrl,
  children,
  ...rest
}: BaseSpaceIconProps) {
  const spaceColor = useMemo(() => generateSpaceColor(spaceUrl || ""), [spaceUrl]);
  if (spaceImageUrl) {
    // Image Version
    return (
      <IconContainer variant={variant} {...rest}>
        <SemblyImage
          // Maintain aspect ratio to be square
          imageKitOptions={[`ar-1-1`]}
          className="h-full w-full "
          src={spaceImageUrl}
          width={spaceIconSize[variant]}
          height={spaceIconSize[variant]}
          alt={spaceName || ""}
        />
        {children}
      </IconContainer>
    );
  }

  const initial = spaceName?.[0]?.toUpperCase() ?? "";
  const progressInDegree = ((progress || 0) / 100) * 360;

  return (
    <IconContainer variant={variant} {...rest}>
      {Boolean(progress) && (
        <div className="absolute z-1 h-full w-full p-2">
          <ProgressLayer
            completeDegree={progressInDegree as number}
            className="z-1 flex h-full w-full items-center justify-center"
          />
        </div>
      )}
      <div className="flex h-full w-full items-center justify-center text-center" style={{ background: spaceColor }}>
        {initial}
      </div>
      {children}
    </IconContainer>
  );
}

type SpaceIconProps = BaseSpaceIconProps &
  React.HTMLAttributes<HTMLDivElement> & {
    showLockIcon?: boolean;
    lockOutlineClass?: string;
  };

function SpaceIcon({ showLockIcon = false, variant = "lg", lockOutlineClass, ...rest }: SpaceIconProps) {
  // the lock is 40% of the icon size
  const lockSize = spaceIconSize[variant] * 0.4;
  return (
    <div className="relative inline-flex">
      <BaseSpaceIcon {...rest} variant={variant}></BaseSpaceIcon>
      {showLockIcon && (
        <div className="absolute bottom-[-6%] right-[-12%] z-2">
          <LockBadgeIcon
            className="text-gray-60"
            width={lockSize}
            height={lockSize}
            lockOutlineClass={lockOutlineClass}
          />
        </div>
      )}
    </div>
  );
}
export default SpaceIcon;
