import type { ReactNode } from "react";
import React, { memo } from "react";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

import LabelTooltip from "@/src/components/sembly-ui/components/LabelTooltip/LabelTooltip";
import { CircularIcon, SettingUpIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { cn } from "@/src/utils/cn";

export type BaseTagSize = "s" | "m";
export interface BaseSettingUpTagWithTooltipProps {
  colorful?: boolean;
  size?: BaseTagSize;
  borderDashed?: boolean;
  portalled?: boolean;
  tooltipContent?: JSX.Element | string;
  className?: string;
  tooltipContentWrapperClassName?: string;
  isTooltipLoading?: boolean;
  onClick?: () => void;
}

export const BaseSettingUpTag = ({
  size = "m",
  borderDashed = true,
  colorful,
  className,
  children,
}: Pick<BaseSettingUpTagWithTooltipProps, "borderDashed" | "className" | "size" | "colorful"> & {
  children?: ReactNode;
}) => {
  const [t] = useTranslation("common");
  return (
    <div
      className={cn(
        "ml-4 flex max-h-full w-max min-w-[87px] items-center justify-center space-x-2 rounded-full bg-transparent",
        { "border px-4": size === "s", "border pl-4 pr-6": size === "m" },
        { "border-dashed": borderDashed },
        {
          "border-gray-20 text-gray-60 hover:bg-black hover:bg-opacity-5 hover:text-gray-80": !colorful,
          "border-purple-40 text-purple-60 hover:bg-purple-20": colorful,
        },
        className,
      )}
    >
      <SettingUpIcon className="h-14 w-14" />
      <span
        className={cn("font-normal uppercase leading-4 tracking-[0.5px]", {
          "text-2xs": size === "s",
          "text-xs": size === "m",
        })}
      >
        {children || t("spaceProposal.settingUpLabel")}
      </span>
    </div>
  );
};

const BaseSettingUpTagWithTooltip = ({
  colorful,
  size = "m",
  borderDashed = true,
  portalled = true,
  tooltipContent,
  className,
  isTooltipLoading,
  tooltipContentWrapperClassName,
  ...rest
}: BaseSettingUpTagWithTooltipProps) => {
  const [t] = useTranslation("common");

  const displayedTooltipContent = (
    <div className="relative">
      <div className={clsx({ "opacity-20": isTooltipLoading })}>
        {tooltipContent || t("spaceProposal.settingUpTooltipDesc")}
      </div>
      {isTooltipLoading && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
          <CircularIcon className="h-30 w-30 text-white" />
        </div>
      )}
    </div>
  );

  return (
    <LabelTooltip
      tooltipClassName={tooltipContentWrapperClassName}
      tooltipAlign="start"
      tooltipSide="bottom"
      tooltipAlignOffset={4}
      forceMountTooltip={isTooltipLoading}
      content={<>{displayedTooltipContent}</>}
      className="inline-flex max-h-full"
      {...rest}
    >
      <BaseSettingUpTag borderDashed={borderDashed} className={className} colorful={colorful} size={size} />
    </LabelTooltip>
  );
};

export default memo(BaseSettingUpTagWithTooltip);
