import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { selectors as zendeskSelectors, slice as zendeskSlice } from "@/src/stores/zendesk";

const useZendesk = () => {
  const dispatch = useDispatch();
  const isZendeskLoaded = useSelector<zendeskSelectors.Zendesk, boolean>(state =>
    zendeskSelectors.isZendeskLoaded(state),
  );

  const loadZendeskScript = useCallback(() => {
    if (!isZendeskLoaded) {
      const script = document.createElement("script");
      script.id = "ze-snippet";
      script.async = true;
      script.src = "https://static.zdassets.com/ekr/snippet.js?key=b2d31c9d-273f-4dd0-92fd-c50f40e405bf";
      document.body.appendChild(script);
    }
  }, [isZendeskLoaded]);

  const activateZendesk = useCallback(() => {
    if (window.zE?.activate) {
      dispatch(zendeskSlice.actions.setIsZendeskScriptLoaded(true));
      window.zE.activate();
    } else {
      // script not yet loaded, retry after certain interval
      setTimeout(activateZendesk, 1500);
    }
  }, [dispatch]);

  const handleOpenZendeskWidget = useCallback(() => {
    loadZendeskScript();
    activateZendesk();
  }, [activateZendesk, loadZendeskScript]);

  return handleOpenZendeskWidget;
};

export default useZendesk;
