import createDynamicReducer from "@/src/stores/createDynamicReducer";

interface PageComponentSize {
  maxWidth?: number;
  spaceListWidth?: number;
  contentWidth?: number;
  tocWidth?: number;
  feedWidth?: number;
  rightSidebarWidth?: number;
}

interface PageComponentSizeAction {
  store: (args: PageComponentSize) => void;
}

const PAGE_COMPONENT_SIZE_REDUCER = "pageComponentSize";

const PageComponentSizeInitialState: PageComponentSize = {};

export const [usePageComponentSize, usePageComponentSizeActions] = createDynamicReducer<
  PageComponentSize,
  PageComponentSizeAction
>({
  name: PAGE_COMPONENT_SIZE_REDUCER,
  initialState: PageComponentSizeInitialState,
  reducers: {
    store: (state, { payload }) => {
      return payload;
    },
  },
});
