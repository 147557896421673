import {
  ROUTER_PATH_SPACE,
  ROUTER_PATH_SPACE_WITH_PRESENTATION_AND_HIGHLIGHT,
  SPACE_PRESENTATION,
} from "@/src/constants/AppConstant";
import parseTemplateString from "@/src/utils/helpers/parseTemplateString";

export function generateTocUrl(spaceUrl: string, sectionId?: number) {
  let url;

  if (sectionId) {
    url = parseTemplateString(ROUTER_PATH_SPACE_WITH_PRESENTATION_AND_HIGHLIGHT, {
      spaceId: spaceUrl,
      presentation: SPACE_PRESENTATION.ALL,
      highlightId: sectionId,
    });
  } else {
    url = parseTemplateString(ROUTER_PATH_SPACE, {
      spaceId: spaceUrl,
    });
  }

  return new URL(url, document.location.origin).href;
}
