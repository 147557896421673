import ColorThief from "colorthief";

import { colors } from "@/src/components/sembly-ui/core/token";

type ColorsType = {
  R: number;
  G: number;
  B: number;
};

// From: http://stackoverflow.com/questions/5623838/ddg#5624139
function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

// Input:
// - coeff: a constant between 0.0 and 1.0. 0.0 means all the way color0, 1.0 means all the way color1
// - color0: {"R": float, "G": float, "B": float}
// - color1: {"R": float, "G": float, "B": float}
// Return:
//   {"R": float, "G": float, "B": float}
export function rgbWeightedAverage(coeff: number, color0: ColorsType, color1: ColorsType) {
  return {
    R: Math.min(255, Math.max(0, Math.round((1 - coeff) * color0.R + coeff * color1.R))),
    G: Math.min(255, Math.max(0, Math.round((1 - coeff) * color0.G + coeff * color1.G))),
    B: Math.min(255, Math.max(0, Math.round((1 - coeff) * color0.B + coeff * color1.B))),
  };
}

// Input:
// - color: {"R": float, "G": float, "B": float}

export function rgbToHex(color: ColorsType) {
  return "#" + componentToHex(color.R) + componentToHex(color.G) + componentToHex(color.B);
}

export function rgbRawToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

// From: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        R: parseInt(result[1], 16),
        G: parseInt(result[2], 16),
        B: parseInt(result[3], 16),
      }
    : null;
}

// From: https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
export function stringToHex(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}

export function findAlphabetIndex(chars: string) {
  const base = "a".charCodeAt(0);
  return chars.split("").map(char => {
    const charCodeAt = char.toLowerCase().charCodeAt(0);
    // Check if char is alphabet otherwise don't subtract with base
    return charCodeAt > base ? charCodeAt - base : charCodeAt;
  });
}

export function generateSpaceColor(spaceUrl: string) {
  const sum = findAlphabetIndex(spaceUrl).reduce((acc, charIndex) => acc + charIndex, 0);
  const spaceColors = Object.values(colors.space);
  const index = sum % spaceColors.length;
  return spaceColors[index];
}

export const RGBToHSL = (r: number, g: number, b: number) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s ? (l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s) : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
};

export function getDominantColorFromImageUrl(url: string): Promise<[number, number, number] | null> {
  return new Promise((resolve, reject) => {
    const colorThief = new ColorThief();
    const img = new Image();

    img.addEventListener("load", function () {
      try {
        const color = colorThief.getColor(img);
        resolve(color);
      } catch (e) {
        reject(null);
      }
    });
    img.addEventListener("error", function () {
      reject(null);
    });

    img.crossOrigin = "Anonymous";
    img.src = url;
  });
}
