import type { TocBreadcrumbPath } from "@/src/domains/content/components/Toc/TocBreadcrumb/TocBreadcrumb";
import { generateTocUrl } from "@/src/domains/content/components/Toc/helpers/TocUrlHelper";

export function generateSnippetUrl(spaceUrl: string) {
  return (item: TocBreadcrumbPath) => {
    if (item.type === "SPACE") {
      return generateTocUrl(spaceUrl);
    } else {
      return generateTocUrl(spaceUrl, item.id);
    }
  };
}
